import React from "react";

function Awards() {
  return (
    <section id="awards">
      <h2>競標與獲獎</h2>
      <ul>
        <li>2025日本國際博覽會小催事場設計競標獲勝。</li>
        <li>太田西複合拠點公共施設基本設計競標成功。</li>
      </ul>
    </section>
  );
}

export default Awards;
