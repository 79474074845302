import React from "react";

function Careers() {
  return (
    <section id="careers">
      <h2>招聘訊息</h2>
      <p>歡迎對創造新事物充滿熱情的設計師加入我們。</p>
    </section>
  );
}

export default Careers;
