import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import { AnimatePresence, motion } from "framer-motion";
import News from "./components/News.jsx";
import Loading from "./components/Loading"; // 確保路徑正確
import Projects from "./components/Projects.jsx";
import Careers from "./components/Careers.jsx";
import Awards from "./components/Awards.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SocialIcons from "./components/SocialIcons";
import { AuroraBackground } from "./components/ui/aurora-background";
import { InfiniteMovingCards } from "./components/ui/infinite-moving-cards";
import {
  LanguageProvider,
  useLanguage,
  LanguageToggleButton,
} from "./components/LanguageContext.jsx"; // 引入 LanguageContextimport Loading from "./components/Loading";
import BackgroundEffect from "./components/BackgroundEffect";
import "./App.css"; // 調整後的 css 放這裡

// Custom Cursor Component

function CustomCursor() {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className="custom-cursor"
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        position: "fixed",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        backgroundColor: "#06d6a0",
        pointerEvents: "none",
        zIndex: 9999,
        transform: "translate(-50%, -50%)",
      }}
    />
  );
}

// Hero Section with Background Image and Restored Effects
function Home() {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  const cardItems = [
    {
      quote: "This is an amazing product!",
      name: "Jarry Chiou",
      title: "CEO of Airus Tech",
    },
    {
      quote:
        "No other interior prodcut can easily absorb CO2 like Andgreen Green Ox",
      name: "Mr. Huang",
      title: "CEO of HEC",
    },
    {
      quote: "Amazing potencial for the next generation airpurifier",
      name: "Alex Huang",
      title: "Architecture Designer",
    },
  ];
  const { language } = useLanguage(); // 从上下文中获取语言

  const images = [
    "/images/hero4.jpg",
    "/images/hero5.jpg",
    "/images/hero6.jpg",
    "/images/hero7.jpg",
    "/images/hero8.jpg",
    "/images/hero9.jpg",
    "/images/hero10.jpg",
  ];

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="snap-container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6 }}
        className="snap-section home-section"
      >
        <BackgroundEffect />
        <section className="hero-section relative bg-cover bg-center flex items-center justify-center">
          <div className="relative flex items-center justify-center ">
            {/* 改為 relative 定位 */}
            <motion.div
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3, duration: 1 }}
              style={{ marginBottom: "10px" }}
              className="text-left text-black"
            >
              <h1 className="text-7xl mx-2 font-futura-bt my-5">
                {language === "zh" ? "綠亦" : "Andgreen"}
              </h1>
              <p className="text-lg mx-2">
                {language === "zh"
                  ? "創新設計・綠色科技"
                  : "Innovative Design・Green Technology"}
              </p>
              <nav>
                <Link
                  to="/news"
                  className="text-black mx-2 text-lg hover:text-gray-300 transition"
                >
                  {language === "zh" ? "最新動態" : "Latest News"}
                </Link>
                <Link
                  to="/projects"
                  className="text-black mx-2 text-lg hover:text-gray-300 transition"
                >
                  {language === "zh" ? "設計及項目" : "Projects"}
                </Link>
                <Link
                  to="/careers"
                  className="text-black mx-2 text-lg hover:text-gray-300 transition"
                >
                  {language === "zh" ? "招聘訊息" : "Careers"}
                </Link>
                <Link
                  to="/awards"
                  className="text-black mx-2 text-lg hover:text-gray-300 transition"
                >
                  {language === "zh" ? "競標與獲獎" : "Awards"}
                </Link>
              </nav>
            </motion.div>
          </div>
          {/* content-container 放在 nav 下面 */}
          <div className="content-container">
            <section className="right-section h-[40rem] rounded-md flex flex-col antialiased bg-transparent dark:bg-transparent dark:bg-grid-white/[0.05] items-center justify-center relative overflow-hidden">
              <InfiniteMovingCards
                items={cardItems}
                direction="left"
                speed="normal"
              />
            </section>
          </div>
        </section>
      </motion.div>{" "}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6 }}
        className="snap-section"
      >
        <section
          className="py-20 flex justify-center items-center"
          style={{ minHeight: "80vh" }}
        >
          <Container fluid>
            <Row className="justify-content-center ">
              <Col md={10}>
                <Slider
                  {...carouselSettings}
                  dots={true}
                  infinite={true}
                  autoplay={true}
                  speed={500}
                  slidesToShow={1}
                  slidesToScroll={1}
                  centerMode={true}
                  centerPadding="0"
                >
                  {Array.isArray(images) && images.length > 0 ? (
                    images.map((image, index) => (
                      <div
                        key={index}
                        className="px-4 flex justify-center items-center"
                      >
                        <motion.div
                          whileHover={{ scale: 1.05 }}
                          className="transition-transform duration-500"
                        >
                          <img
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="w-full mx-auto object-cover rounded-lg"
                            style={{
                              maxHeight: "800px",
                              objectFit: "contain",
                              borderRadius: "1rem",
                            }}
                          />
                        </motion.div>
                      </div>
                    ))
                  ) : (
                    <div>No images available.</div>
                  )}
                </Slider>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>
      {/* Contact Section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6 }}
        className="snap-section"
      >
        <section className="py-16 text-left bg-green flex flex-col lg:flex-row justify-start items-center">
          <div className="text-left mx-4">
            <h2 className="text-3xl lg:text-4xl font-futura-bt text-black mb-6">
              {language === "zh" ? "聯絡我們" : "Contact Us"}
            </h2>
            <p className="text-black mb-4 font-futura-bt">
              {language === "zh"
                ? "歡迎通過 Email 聯絡我們 : mail@andgreen.org"
                : "Feel free to contact us via email: mail@andgreen.org"}
            </p>
            <a
              href="mailto:mail@andgreen.org"
              className="text-black underline hover:text-gray-300 transition"
            >
              {language === "zh" ? "發送訊息" : "Send Message"}
            </a>
          </div>
          <div className="social-icons-contact ">
            <SocialIcons />
          </div>
        </section>
      </motion.div>
    </div>
  );
}

// Main App Component
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <LanguageProvider>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <CustomCursor />
          <div className="fixed inset-0 z-[10] bg-zinc-50 dark:bg-zinc-900 text-slate-950 transition-bg">
            <AuroraBackground />

            {/* Aurora 背景層 */}
          </div>
          <Router>
            <div className="scroll-container">
              <header>
                <div className="flex items-center justify-between logo-container">
                  <h2 className="text-black font-futura-bt text-3xl">
                    Andgreen Co. Ltd.
                  </h2>
                </div>
                <LanguageToggleButton className="ml-auto text-black underline hover:text-gray-500 transition" />
              </header>{" "}
              <main className="pt-20 p-8">
                <AnimatePresence mode="wait">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/awards" element={<Awards />} />
                  </Routes>
                </AnimatePresence>
                <footer></footer>
              </main>
            </div>
          </Router>
        </>
      )}
    </LanguageProvider>
  );
}

export default App;
