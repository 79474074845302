import React, { createContext, useContext, useState } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en"); // 默认语言为英语

  const toggleLanguage = () => {
    setLanguage((prev) => (prev === "en" ? "zh" : "en")); // 切换语言
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

// 语言切换按钮组件
export const LanguageToggleButton = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <button
      onClick={toggleLanguage}
      className="text-black underline hover:text-gray-500 transition"
      style={{
        fontSize: "14px",
        background: "none",
        border: "none",
        padding: "0",
        cursor: "pointer",
        right: "11px", // 將按鈕與容器的右邊緣稍微距離，根據需要微調
        top: "100%", // 按鈕放置在標題的下方
        transform: "translateY(-50%)", // 調整按鈕的垂直位置
        boxSizing: "border-box",
        borderRadius: "0px",
        marginRight: "8px",
        marginBottom: "-140px",
        marginLeft: "-35px",
        position: "relative",
      }}
    >
      {language === "zh" ? "Eng." : "中文"}
    </button>
  );
};
